<template>
	<div>
		<div class="text-center mb-10">
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.change-password.request.title') }}
			</h1>
			<span class="d-inline-block mb-2">{{ $vuetify.lang.t('$vuetify.change-password.request.subtitle') }}</span>
			<v-chip
				class="mb-0"
				outlined
				link
				@click="$emit('progress', true);$router.push({ name: 'signin-identifier', query: { continue: query.continue } })"
			>
				<v-avatar left>
					<v-icon color="secondary">
						mdi-account-circle
					</v-icon>
				</v-avatar>
				{{ $store.state.auth.identifier }}
				<v-avatar right>
					<v-icon color="secondary">
						mdi-chevron-down
					</v-icon>
				</v-avatar>
			</v-chip>
		</div>

		<v-form @submit.prevent="next" autocomplete="off">
			<v-text-field
				dense
				ref="password"
				v-model.trim="password"
				class="mb-5"
				:append-icon="show.password ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
				:type="show.password ? 'text' : 'password'"
				:label="$vuetify.lang.t('$vuetify.change-password.request.form.password.label')"
				name="password"
				hide-details="auto"
				outlined
				:error-messages="passwordError"
				@click:append="show.password = !show.password"
				@input="$v.password.$touch()"
				autocomplete="new-password"
				required
			/>
			<v-text-field
				dense
				ref="passconf"
				v-model.trim="passconf"
				class="mb-0"
				:append-icon="show.passconf ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
				:type="show.passconf ? 'text' : 'password'"
				:label="$vuetify.lang.t('$vuetify.change-password.request.form.passconf.label')"
				name="passconf"
				hide-details="auto"
				outlined
				:error-messages="passconfError"
				@click:append="show.passconf = !show.passconf"
				@input="$v.passconf.$touch()"
				required
			/>
		</v-form>

		<div class="d-flex justify-space-between mt-10">
			<v-btn
			  :disabled="$v.$invalid"
			  class="text-none ml-auto"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  @click="next"
			>
				{{ $vuetify.lang.t('$vuetify.change-password.request.action.next') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
	name: 'change-password-request',
	props: {
		token: { type: String, default: '' },
		query: { type: Object, default() { return {} } }
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.change-password.request.title'),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale }
      	}
    },
	validations: {
		password: { required, minLength: minLength(8) },
		passconf: { required, sameAsPassword: sameAs('password') }
    },
	data: () => ({
		error: {
			password: null,
			passconf: null
		},
		show: {
			password: false,
			passconf: false
		},
		password: '',
		passconf: ''
	}),
	computed: {
		passwordError() {
			if ( ! this.$v.password.$dirty) {
				return null;
			} else if ( ! this.$v.password.required) { 
				return this.$vuetify.lang.t('$vuetify.change-password.request.form.password.error.required');
			} else if ( ! this.$v.password.minLength) {
				return this.$vuetify.lang.t('$vuetify.change-password.request.form.password.error.minlength');
			} else if (this.error.password) {
				return this.$vuetify.lang.t('$vuetify.change-password.request.form.password.error.'+this.error.password);
			}

			return this.error.password;
		},
		passconfError() {
			if ( ! this.$v.passconf.$dirty) {
				return null;
			} else if ( ! this.$v.passconf.required) { 
				return this.$vuetify.lang.t('$vuetify.change-password.request.form.passconf.error.required');
			} else if ( ! this.$v.passconf.sameAsPassword) {
				return this.$vuetify.lang.t('$vuetify.change-password.request.form.passconf.error.confirm');
			} else if (this.error.passconf) {
				return this.$vuetify.lang.t('$vuetify.change-password.request.form.passconf.error.'+this.error.passconf);
			}

			return this.error.passconf;
		}
	},
	mounted() {
		this.$emit('progress', false);
	},
	methods: {
		next() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			this.error.password = null
			this.error.passconf = null;
			this.$emit('progress', true);

			this.$store.dispatch("auth/change-password", { 
				token: this.token,
				password: this.password,
				passconf: this.passconf
			}).then(response => {
				this.$router.push({ name: 'change-password-success', query: { continue: this.query.continue }, params: { success: true } });
			}).catch(error => {
				this.$emit('progress', false);
				this.error.password = 'server';
				if (error.response) {
		            if ([401, 404].includes(error.response.status)) {
						this.error.passconf = null;
	            		this.$router.push({ name: 'rejected-token', query: { continue: this.query.continue } });
	            	}
		        }

				this.$refs.passconf.focus();
			});
		}
	}
}
</script>