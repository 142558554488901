<template>
	<div>
		<div class="text-center mb-5">
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.forgot-password.request.title') }}
			</h1>
			<span class="d-inline-block mb-2">{{ $vuetify.lang.t('$vuetify.forgot-password.request.subtitle') }}</span>
			<v-chip
				class="mb-0"
				outlined
				link
				@click="$emit('progress', true);$router.push({ name: 'signin-identifier', query: query })"
			>
				<v-avatar left>
					<v-icon color="secondary">
						mdi-account-circle
					</v-icon>
				</v-avatar>
				{{ $store.state.auth.identifier }}
				<v-avatar right>
					<v-icon color="secondary">
						mdi-chevron-down
					</v-icon>
				</v-avatar>
			</v-chip>
		</div>

		<div class="text-body-2 text--secondary mb-5">
			{{ $vuetify.lang.t('$vuetify.forgot-password.request.form.hint', { brand: $whitelabel.getClient().name }) }}
		</div>

		<v-form @submit.prevent="next" autocomplete="off">
			<v-text-field
				dense
				ref="email"
				v-model.trim="email"
				class="mb-0"
				type="email"
				:label="$vuetify.lang.t('$vuetify.forgot-password.request.form.email.label')"
				name="email"
				hide-details="auto"
				outlined
				:error-messages="emailError"
				@input="$v.email.$touch()"
				autocomplete="off"
				required
			/>
		</v-form>

		<div class="d-flex justify-space-between mt-10">
			<v-btn
				class="text-none letter-spacing-0"
				style="margin-left: -16px;"
				color="primary"
				text
				@click="$emit('progress', true);$router.push({ name: 'signin-identifier', query: query })"
			>
				{{ $vuetify.lang.t('$vuetify.forgot-password.request.action.signin') }}
			</v-btn>
			<v-btn
			  :disabled="$v.$invalid"
			  class="text-none"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  @click="next"
			>
				{{ $vuetify.lang.t('$vuetify.forgot-password.request.action.next') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
	name: 'forgot-password-request',
	props: {
		query: { type: Object, default() { return {} } },
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.forgot-password.request.title'),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale }
      	}
    },
	validations: {
		email: { required, email },
    },
	data: () => ({
		error: null,
		email: ''
	}),
	computed: {
		emailError() {
			if ( ! this.$v.email.$dirty) {
				return null;
			} else if ( ! this.$v.email.required) { 
				return this.$vuetify.lang.t('$vuetify.forgot-password.request.form.email.error.required');
			} else if ( ! this.$v.email.email) { 
				return this.$vuetify.lang.t('$vuetify.forgot-password.request.form.email.error.email');
			} else if (this.$i18n.te('$vuetify.forgot-password.request.form.email.error.'+this.error)) {
				return this.$vuetify.lang.t('$vuetify.forgot-password.request.form.email.error.'+this.error);
			}

			return this.error;
		}
	},
	mounted() {
		this.$emit('progress', false);
		if (this.validEmail(this.$store.state.auth.identifier)) {
			this.email = this.$store.state.auth.identifier;
		}
	},
	methods: {
		validEmail (email) {
	      	var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	      	return re.test(email)
	    },
		next() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			this.error = null;
			this.$emit('progress', true);
			
			this.$store.dispatch("auth/forgot-password", { 
				username: this.$store.state.auth.identifier,
				email: this.email
			}).then(response => {
				this.$router.push({ name: 'forgot-password-verify', query: {...this.query, ...{ token: response.data.data.token }} }).catch(error => {
					this.$emit('progress', false);
				});
			}).catch(error => {
				this.$emit('progress', false);
				this.error = 'server';
				if (error.response) {
		            switch(error.response.status) {
					  	case 404:
					   	 	this.error = 'notfound';
					    	break;
						case 400:
							this.error = error.response.data.error.description;
							break;
					  	default:
					    	this.error = 'server';
					}
		        }

				this.$refs.email.focus();
			});
		}
	}
}
</script>