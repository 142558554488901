<template>
	<div>
		<div class="text-center mb-10">
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.forgot-password.verify.title') }}
			</h1>
			<span class="d-inline-block mb-2">{{ $vuetify.lang.t('$vuetify.forgot-password.verify.subtitle', { email: email }) }}</span>
			<v-chip
				class="mb-0"
				outlined
				link
				@click="$emit('progress', true);$router.push({ name: 'signin-identifier', query: { continue: query.continue } })"
			>
				<v-avatar left>
					<v-icon color="secondary">
						mdi-account-circle
					</v-icon>
				</v-avatar>
				{{ $store.state.auth.identifier }}
				<v-avatar right>
					<v-icon color="secondary">
						mdi-chevron-down
					</v-icon>
				</v-avatar>
			</v-chip>
		</div>

		<v-form @submit.prevent="next" autocomplete="off">
			<v-text-field
				dense
				ref="code"
				v-model.trim="code"
				class="mb-0"
				type="text"
				:label="$vuetify.lang.t('$vuetify.forgot-password.verify.form.code.label')"
				name="code"
				hide-details="auto"
				outlined
				:error-messages="codeError"
				@input="$v.code.$touch()"
				required
				autocomplete="off"
			/>
		</v-form>

		<div class="d-flex justify-space-between mt-10">
			<v-btn
				class="text-none letter-spacing-0"
				style="margin-left: -16px;"
				color="primary"
				text
				:loading="resend.loading"
				:disabled="resend.disabled"
				@click="sendCode"
			>
				{{ (resend.disabled ? $vuetify.lang.t('$vuetify.forgot-password.verify.action.sendcodein', {time: resend.time}) : $vuetify.lang.t('$vuetify.forgot-password.verify.action.sendcode')) }}
			</v-btn>
			<v-btn
			  :disabled="$v.$invalid"
			  class="text-none"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  @click="next"
			>
				{{ $vuetify.lang.t('$vuetify.forgot-password.verify.action.next') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators';

export default {
	name: 'forgot-password-verify',
	props: {
		email: { type: String, default: '' },
		token: { type: String, default: '' },
		query: { type: Object, default() { return {} } },
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.forgot-password.verify.title'),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale }
      	}
    },
	validations: {
		code: { required, minLength: minLength(6), maxLength: maxLength(6), numeric },
    },
	data: () => ({
		error: null,
		code: '',
		resend: {
			try: 1,
			time: 15,
			disabled: true,
			loading: false
		}
	}),
	computed: {
		codeError() {
			if ( ! this.$v.code.$dirty) {
				return null;
			} else if ( ! this.$v.code.required) { 
				return this.$vuetify.lang.t('$vuetify.forgot-password.verify.form.code.error.required');
			} else if ( ! this.$v.code.numeric) {
				return this.$vuetify.lang.t('$vuetify.forgot-password.verify.form.code.error.numeric');
			} else if ( ! this.$v.code.minLength) {
				return this.$vuetify.lang.t('$vuetify.forgot-password.verify.form.code.error.minlength');
			} else if ( ! this.$v.code.maxLength) {
				return this.$vuetify.lang.t('$vuetify.forgot-password.verify.form.code.error.maxlength');
			} else if (this.$i18n.te('$vuetify.forgot-password.verify.form.code.error.'+this.error)) {
				return this.$vuetify.lang.t('$vuetify.forgot-password.verify.form.code.error.'+this.error);
			}

			return this.error;
		}
	},
	mounted() {
		this.$emit('progress', false);
		this.enableSendCode();
	},
	methods: {
		enableSendCode() {
			if (this.resend.time>=1) {
				this.resend.disabled = true;
				setTimeout(() => {
					this.resend.time--;
					this.enableSendCode();
				}, 1000);
			} else {
				this.resend.try++;
				this.resend.disabled = false;
				this.resend.time = 15*this.resend.try;
			}
		},
		sendCode() {
			if ( ! this.resend.disabled) {
		    	this.resend.loading = true;
				this.$store.dispatch('alert/hide');
				this.$store.dispatch("auth/forgot-password", { 
					username: this.$store.state.auth.identifier,
					email: this.email
				}).then(response => {
			    	this.resend.loading = false;
			    	this.enableSendCode();
			    	this.$router.replace({ name: 'forgot-password-verify', query: {...this.query, ...{ token: response.data.data.token }}, params: { email: this.email } });
				}).catch(error => {
			    	this.resend.loading = false;
			    	this.enableSendCode();
			    	this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.error.server')});
					if (error.response && error.response.status) {
			            if (error.response.status == 404) {
		                    this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.forgot-password.request.form.email.error.notfound')});
		                }
			        }

					this.$store.dispatch('alert/show');
				});
			}
		},
		next() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			this.error = null;
			this.$emit('progress', true);

			this.$store.dispatch("auth/code-verify", { 
				token: this.token,
				code: this.code
			}).then(response => {
				this.$router.push({ name: 'change-password-request', query: {...this.query, ...{ token: response.data.data.token }} }).catch(error => {
					this.$emit('progress', false);
				});
			}).catch(error => {
				this.$emit('progress', false);
				this.error = 'server';
				if (error.response) {
		            switch(error.response.status) {
					  	case 401:
					   	 	this.error = 'unauthorized';
					    	break;
					  	case 404:
					   	 	this.error = 'notfound';
					    	break;
						case 400:
							this.error = error.response.data.error.description;
							break;
					  	default:
					    	this.error = 'server';
					}
		        }

				this.$refs.code.focus();
			});
		}
	}
}
</script>